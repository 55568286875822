import { FactoryProvider, InjectionToken, Optional } from '@angular/core';
import { IS_BROWSER } from './is-platform.provider';
import { Request } from 'express';
import { REQUEST } from 'ngx-cookie-service-ssr';

export const BROWSER_LANGUAGE = new InjectionToken<string>('browser-language');

export const BROWSER_LANGUAGE_PROVIDER: FactoryProvider = {
  provide: BROWSER_LANGUAGE,
  deps: [IS_BROWSER, [new Optional(), REQUEST]],
  useFactory: (isBrowser: boolean, req?: Request): string => {
    return isBrowser ? getLanguageFromBrowser() : getLanguageFromRequest(req);
  },
};

const DEFAULT_LANGUAGE = 'pl-PL';

const getLanguageFromBrowser = (): string => navigator.language || DEFAULT_LANGUAGE;

const getLanguageFromRequest = (req?: Request): string =>
  req?.acceptsLanguages()?.[0] || DEFAULT_LANGUAGE;
